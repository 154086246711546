import styled from "styled-components";
import { Spinner } from "@wayflyer/flyui";

const LoadingWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const Loading = () => {
  return (
    <LoadingWrapper>
      <Spinner size="large" />
    </LoadingWrapper>
  );
};
