import { useRoutes } from "react-router-dom";

import { CustomerRoutes } from "./customer";

export const UnifiedRoutes = () => {
  const rendered = useRoutes([
    {
      path: "/*",
      element: <CustomerRoutes />,
    },
  ]);

  return rendered;
};
