import { ComponentProps } from "react";
// eslint-disable-next-line import/no-named-as-default
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import {
  IS_PROD,
  POSTHOG_DEV_KEY,
  POSTHOG_HOST,
  POSTHOG_KEY,
} from "../utils/env";
import { isProd as isHostProd } from "../utils/host";

const isProd = IS_PROD && isHostProd;

posthog.init(isProd ? POSTHOG_KEY : POSTHOG_DEV_KEY, {
  api_host: POSTHOG_HOST,
  person_profiles: "identified_only",
  capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  capture_pageleave: true,
  autocapture: {
    element_allowlist: ["a", "button"],
  },
});

export function PHProvider({
  children,
}: Omit<ComponentProps<typeof PostHogProvider>, "client">) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
