import { useAuth } from "@wayflyer/auth-sdk";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Resets any auth errors on location change
 */
export const ResetAuthErrors: FC = ({ children }) => {
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    auth?.resetError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <>{children}</>;
};
