import { gaChannel } from "./ga";

export type WfEventContext<P = Record<string, string | boolean | null>> = P & {
  page_location: string;
  page_referrer: string;
  service: string; // eg "auth", "insights", "freight"
};

export interface WfEvent {
  name: string; // eg "wfa_pageview"
  context: WfEventContext;
  attrs?: any;
}

export type WfChannel = (event: WfEvent) => Promise<unknown>;
type ChannelRegistry = Record<string, WfChannel>;

const defaultChannels: ChannelRegistry = {
  ga: gaChannel,
};

/**
 * Responsible for composing all the channels into a single pushEvent function that can be passed to "emitters"
 *
 * Accepts an optional object of channels which allows the defaults to be overridden or new channels to be added.
 * New channels could target the same host (eg ga) with domain specific logic.
 *
 */
interface PushEventProps {
  channels?: ChannelRegistry;
  exclude?: string[];
}
export const pushEventFactory =
  ({ channels, exclude }: PushEventProps = {}): WfChannel =>
  async (event: WfEvent) => {
    const effectiveChannels = { ...defaultChannels, ...channels };
    await Promise.all(
      Object.keys(effectiveChannels)
        .filter((k) => (exclude ? !exclude?.includes(k) : true))
        .map((channel) => {
          // TODO: some common  stuff like logging
          // Pass our event into the channel
          effectiveChannels[channel](event);
        }),
    );
  };
