import { useIntl } from "react-intl";
import { Text, Flex, Image, useMediaQuery } from "@wayflyer/flyui";
import styled from "styled-components";
import React from "react";

const SecondaryPanelContentLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const query = useMediaQuery();
  const alignment = query("md").windowLte ? "center" : "flex-start";

  return (
    <Flex
      direction="column"
      align={alignment}
      justify="space-between"
      height="100%"
      width="100%"
      gap="16"
    >
      {children}
    </Flex>
  );
};

const logos = [
  {
    src: "/fly-assets/logos/fresh-clean-threads-logo.svg",
    alt: "Wild logo",
    height: "30px",
  },
  { src: "/fly-assets/logos/wild-logo.svg", alt: "Wild logo", height: "30px" },
  {
    src: "/fly-assets/logos/the-oodie-logo.svg",
    alt: "The Oodie logo",
    height: "38px",
  },
  {
    src: "/fly-assets/logos/true-classic-logo.svg",
    alt: "True Classic logo",
    height: "38px",
  },
  {
    src: "/fly-assets/logos/tonic-logo.svg",
    alt: "Tonic logo",
    height: "38px",
  },
];

const LogoImg = styled(Image)<{ $maxHeight: string }>`
  max-height: ${({ $maxHeight }) => $maxHeight};
`;

export const AuthMarketingPanel = () => {
  const intl = useIntl();

  const query = useMediaQuery();
  const alignment = query("md").windowLte ? "center" : "flex-start";

  return (
    <SecondaryPanelContentLayout>
      <Flex gap="12" direction="column" align={alignment} width="100%">
        <Text color="onDark" size="2xl">
          {intl.formatMessage({
            id: "auth: marketing panel in great company",
            defaultMessage: "You’re in great company",
          })}
        </Text>
        {logos.map((logo, index) => (
          <LogoImg
            key={index}
            src={logo.src}
            alt={logo.alt}
            $maxHeight={logo.height}
          />
        ))}
      </Flex>
    </SecondaryPanelContentLayout>
  );
};
