import { useCallback } from "react";
import { IntlShape } from "react-intl";

import type { MessageObject } from "../screens/interface";
import { UNEXPECTED_ERROR_CODE } from "../utils/constants";

type MessageGetter = () => string | MessageObject | undefined;
type MessageLookup = () => Record<string, MessageGetter>;

export const useMessages = (intl: IntlShape) => {
  const fallback = intl.formatMessage({
    id: "auth: fallback error",
    defaultMessage: "An unexpected error occurred. Please contact support.",
  });

  const errorLookup = useCallback<MessageLookup>(
    () => ({
      "An account with the given email already exists.": () =>
        intl.formatMessage({
          id: "auth: email exists",
          defaultMessage: "An account with the given email already exists.",
        }),
      "Incorrect username or password.": () =>
        intl.formatMessage({
          id: "auth: login wrong credential",
          defaultMessage: "Incorrect email or password.",
        }),
      // TODO: Ensure these map correctly and list is complete
      "No user found with the email": () =>
        intl.formatMessage({
          id: "auth: no user found with email",
          defaultMessage: "No user found with the email",
        }),
      "UserMigration failed with error Failed to authenticate.": () =>
        intl.formatMessage({
          id: "auth: login wrong credential",
          defaultMessage: "Incorrect email or password.",
        }),
      "User does not exist.": () =>
        intl.formatMessage({
          id: "auth: login wrong credential",
          defaultMessage: "Incorrect email or password.",
        }),
      "PreSignUp failed with error Full name with length of 3 or more is required..":
        () =>
          intl.formatMessage({
            id: "auth: full name min length 3",
            defaultMessage: "Full name must have at least 3 letters.",
          }),
      "Presignup failed with error Users email already registered..": () =>
        intl.formatMessage({
          id: "auth: email exists",
          defaultMessage: "An account with the given email already exists.",
        }),
      "PreSignUp failed with error Unable to reset password for this account..":
        () =>
          intl.formatMessage({
            id: "auth: cannot reset oauth",
            defaultMessage:
              "Unable to reset password for this account. Try logging in with Google.",
          }),
      "Password did not conform with policy: Password must have uppercase characters":
        () =>
          intl.formatMessage({
            id: "auth: password no uppercase",
            defaultMessage: "Password must contain an uppercase letter.",
          }),
      "Password does not conform to policy: Password must have uppercase characters":
        () =>
          intl.formatMessage({
            id: "auth: password no uppercase",
            defaultMessage: "Password must contain an uppercase letter.",
          }),
      "Password did not conform with policy: Password must have lowercase characters":
        () =>
          intl.formatMessage({
            id: "auth: password no lowercase",
            defaultMessage: "Password must contain a lowercase letter.",
          }),
      "Password does not conform to policy: Password must have lowercase characters":
        () =>
          intl.formatMessage({
            id: "auth: password no lowercase",
            defaultMessage: "Password must contain a lowercase letter.",
          }),
      "Password attempts exceeded": () =>
        intl.formatMessage({
          id: "auth: retry attempts exceeded",
          defaultMessage: "Too many failed logins. Try again in 30 seconds.",
        }),
      "Invalid reset confirmation code.": () => ({
        message: intl.formatMessage({
          id: "auth: invalid reset code",
          defaultMessage:
            "We've emailed you a new password reset link, valid for up to an hour.",
        }),
        heading: intl.formatMessage({
          id: "auth: invalid reset code heading",
          defaultMessage: "Password reset link expired",
        }),
        type: "warning",
      }),
      "Attempt limit exceeded, please try after some time.": () => ({
        message: intl.formatMessage({
          id: "auth: attempt limit exceeded",
          defaultMessage: "Please try again after some time.",
        }),
        heading: intl.formatMessage({
          id: "auth: attempt limit exceeded heading",
          defaultMessage: "Password reset attempt limit exceeded",
        }),
        type: "critical",
      }),
      "Google users cannot reset their password.": () => ({
        message: intl.formatMessage({
          id: "auth: google user reset password",
          defaultMessage: "This Wayflyer account uses Google sign-in.",
        }),
        heading: intl.formatMessage({
          id: "auth: google user reset password heading",
          defaultMessage: "Sign in with Google",
        }),
        type: "warning",
      }),
      "Email not valid": () =>
        intl.formatMessage({
          id: "auth: email invalid",
          defaultMessage:
            "Email address not valid. Please provide a valid email address.",
        }),
      "Password contains personal information": () =>
        intl.formatMessage({
          id: "auth: password contains personal",
          defaultMessage:
            "Password contains personal information. Please try another.",
        }),
      "Password is too similar to personal information": () =>
        intl.formatMessage({
          id: "auth: password similar personal",
          defaultMessage:
            "Password is too similar to personal information. Please try another.",
        }),
      "Resume session failed.": () => undefined,
      [UNEXPECTED_ERROR_CODE]: () => fallback,
    }),
    [intl, fallback],
  );

  return (err: string): string | undefined | MessageObject => {
    if (err) {
      const lookup = errorLookup();
      const key =
        Object.keys(lookup).find((key) => key.includes(err.trim())) ||
        UNEXPECTED_ERROR_CODE;
      return key && lookup[key]?.();
    } else {
      return undefined;
    }
  };
};
