import {
  AnalyticsEventId,
  AnalyticsEventPayload,
  AnalyticsProvider,
} from "@wayflyer/flyui";
import { PropsWithChildren } from "react";

import { useAnalyticsContext } from "./AnalyticsContext";
import { pushEventFactory } from "./channels";

type FlyuiAnalyticsHandlerProps = PropsWithChildren<Record<string, unknown>>;

export const FlyuiAnalytics = ({ children }: FlyuiAnalyticsHandlerProps) => {
  const pushEvent = pushEventFactory();
  const context = useAnalyticsContext();

  const flyUIAnalyticsHandler = async (eventPayload: AnalyticsEventPayload) => {
    switch (eventPayload.id) {
      case AnalyticsEventId.click:
        await pushEvent({
          context,
          name: "wfa_click",
          attrs: eventPayload.payload,
        });
        break;
      case AnalyticsEventId.inlineError:
        await pushEvent({
          context,
          name: "wfa_inlineError",
          attrs: eventPayload.payload,
        });
        break;
    }
  };

  return (
    <AnalyticsProvider analyticsHandler={flyUIAnalyticsHandler}>
      {children}
    </AnalyticsProvider>
  );
};
