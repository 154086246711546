import { BaseConfig, Scripts } from "./Scripts";

export const GTM = ({ id }: BaseConfig) => {
  return (
    <Scripts<BaseConfig>
      config={{ id }}
      scripts={[
        {
          type: "script",
          content: () => "window.dataLayer = window.dataLayer || [];",
        },
        {
          type: "script",
          content: ({ id }) =>
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}')`,
        },
        {
          type: "noscript",
          content: ({ id }) =>
            `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        },
      ]}
    />
  );
};
