import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react";

const LocalErrorContext = createContext<{
  error?: string;
  setError: Dispatch<SetStateAction<string>>;
}>({ error: undefined, setError: () => {} });

export const LocalErrorProvider: FC = ({ children }) => {
  const [error, setError] = useState("");
  return (
    <LocalErrorContext.Provider value={{ error, setError }}>
      {children}
    </LocalErrorContext.Provider>
  );
};

export const useLocalError = () => useContext(LocalErrorContext);
