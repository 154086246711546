import {
  AuthEvent,
  AuthListener,
  AuthProvider,
  AxiosProvider,
  useAuth,
} from "@wayflyer/auth-sdk";
import { Loading } from "@wayflyer/auth-ui";
import { usePostHog } from "posthog-js/react";
import { useHref, useRoutes } from "react-router-dom";

import { useAnalyticsContext } from "../../../../src/analytics/AnalyticsContext";
import { pushEventFactory } from "../../../../src/analytics/channels";
import { LocalErrorProvider } from "../../../../src/contexts/LocalErrorContext";
import { getBaseUrl } from "../../../../src/utils/constants";
import { AnalyticsNest } from "../../../analytics/AnalyticsNest";
import { PHProvider } from "../../../analytics/PostHogProvider";
import { isLocalhost } from "../../../utils/host";
import { ROUTES } from "../sharedRoutes";

import { customerRoutes } from "./customerRoutes";
import { ResetAuthErrors } from "./resetAuthErrors";

/**
 * Customer router
 */

export const CustomerRoutes = () => {
  const pushEvent = pushEventFactory();
  const context = useAnalyticsContext();
  const posthog = usePostHog();

  const cookieDomain = isLocalhost() ? "localhost" : window.location.host;
  const oAuthRedirect = useHref(ROUTES.OAUTH);
  const oAuthLogout = useHref(ROUTES.LOGIN); // Redirect to login after OAuth logout

  return (
    <AuthProvider
      config={{
        ...__WF_AUTH_CONFIGS__.customer,
        authApiBaseUrl: getBaseUrl(),
        cookieDomain,
        oauthRedirectUrl: `${window.location.origin}${oAuthRedirect}`,
        oauthLogoutUrl: `${window.location.origin}${oAuthLogout}`,
        headers: {
          "X-POSTHOG-SESSION-ID": posthog.get_session_id(),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error - private property but was told by posthog to use it
          "X-POSTHOG-WINDOW-ID": posthog.sessionManager?._windowId,
          "X-POSTHOG-CURRENT-URL": window.location.href,
        },
      }}
      listeners={(auth): AuthListener[] => {
        return [
          {
            event: AuthEvent.OAUTH_COMPLETE,
            callback: async () => {
              await pushEvent({
                context,
                name: "wfe_registration",
                attrs: { error: auth?.error },
              });
            },
            name: "oauth-listener",
          },
          {
            event: AuthEvent.SIGNUP,
            callback: async () => {
              await pushEvent({
                context,
                name: "wfe_registration",
                attrs: { error: auth?.error },
              });
            },
            name: "signup-listener",
          },
          {
            event: AuthEvent.SIGNIN_SUCCESS,
            callback: async () => {
              await pushEvent({
                context,
                name: "wfe_login",
                attrs: { result: "success" },
              });
            },
            name: "signin-listener",
          },
          {
            event: AuthEvent.SIGNIN_FAIL,
            callback: async () => {
              await pushEvent({
                context,
                name: "wfe_login",
                attrs: { result: "fail" },
              });
            },
            name: "signin-fail-listener",
          },
        ];
      }}
      withSessionRefresher={false}
    >
      <PHProvider>
        <AnalyticsNest>
          <AxiosProvider>
            <ResetAuthErrors>
              <LocalErrorProvider>
                <RouteWrapper />
              </LocalErrorProvider>
            </ResetAuthErrors>
          </AxiosProvider>
        </AnalyticsNest>
      </PHProvider>
    </AuthProvider>
  );
};

const RouteWrapper = () => {
  const auth = useAuth();
  const rendered = useRoutes(customerRoutes);

  if (!auth || auth.isInitialising) {
    return <Loading />;
  } else {
    return <>{rendered}</>;
  }
};
