import { BaseConfig, Scripts } from "./Scripts";

interface HubspotConfig extends BaseConfig {
  conversationConfig?: {
    identificationEmail: string;
    identificationToken: string;
  };
  hubspotEnv?: string;
}

export const Hubspot = (config: HubspotConfig) => {
  return (
    <Scripts<HubspotConfig>
      config={config}
      scripts={[
        {
          type: "script",
          content: ({ conversationConfig }) =>
            `window.hsConversationsSettings = ${JSON.stringify(
              conversationConfig || {},
            )}`,
        },
      ]}
    />
  );
};
