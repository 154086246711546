import { useAuth } from "@wayflyer/auth-sdk";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { WfEventContext } from "./channels";

const Context = createContext<AuthAnalyticsContext>({
  userId: null,
  isStaff: false,
  page_location: location.pathname,
  page_referrer: document.referrer && new URL(document.referrer).pathname,
  service: "auth",
});

type AnalyticsContextProps = PropsWithChildren<{
  service: string;
}>;

type AuthAnalyticsContext = WfEventContext<{
  userId: string | null;
  isStaff: boolean;
}>;

export const AnalyticsContext = ({
  children,
  service,
}: AnalyticsContextProps) => {
  const auth = useAuth();
  const location = useLocation();
  const contextValue = useMemo((): AuthAnalyticsContext => {
    return {
      userId: auth?.session?.getUsername() || null,
      isStaff: false,
      page_location: location.pathname,
      page_referrer: document.referrer && new URL(document.referrer).pathname,
      service,
    };
  }, [auth?.session, service, location]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useAnalyticsContext = () => useContext(Context);
