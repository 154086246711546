import { Helmet } from "react-helmet";

export interface ScriptsProps<TConfig> {
  config: TConfig;
  scripts: {
    type: "script" | "noscript";
    content?: (config: TConfig) => string;
    attributes?: Record<string, string>;
  }[];
}

export interface BaseConfig {
  id: string;
}

export const Scripts = <TConfig,>({
  config,
  scripts,
}: ScriptsProps<TConfig>) => {
  return (
    <Helmet>
      {scripts.map((script, key) => {
        switch (script.type) {
          case "script":
            return (
              <script {...script.attributes} key={key}>
                {script.content?.(config)}
              </script>
            );
          case "noscript":
            return <noscript key={key}>{script.content?.(config)}</noscript>;
        }
      })}
    </Helmet>
  );
};
