import { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

import { useAnalyticsContext } from "./AnalyticsContext";
import { pushEventFactory } from "./channels";

type AuthAnalyticsProps = PropsWithChildren<Record<string, unknown>>;

const EXCLUDED = ["success", "redirect", "oauth"];

export const RouterAnalytics = ({ children }: AuthAnalyticsProps) => {
  const pushEvent = pushEventFactory();
  const context = useAnalyticsContext();
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    if (!EXCLUDED.some((v) => location.pathname.includes(v))) {
      pushEvent({ context, name: "wf_pageview" });
      if (posthog) {
        posthog.capture("$pageview");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <>{children}</>;
};
