import { useAuth } from "@wayflyer/auth-sdk";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../sharedRoutes";

/**
 * Protected component
 */
export const Protected: FC = ({ children }): JSX.Element => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth && !auth.isLoading && !auth.session?.isValid()) {
      navigate(`../${ROUTES.LOGIN}`, { replace: true });
    }
  }, [auth, auth?.session, navigate]);

  return <>{children}</>;
};
