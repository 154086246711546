import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Text, Link, GoogleButton, FormLayout, Divider } from "@wayflyer/flyui";

import { NotificationBanner } from "../notification-banner";

import { SignInCTAProps, GoogleSignupProps } from "./signup-form.types";

const TAndCBox = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text.default};
`;

export const TermsAndConditions = () => {
  return (
    <TAndCBox>
      <FormattedMessage
        id="auth: terms and conditions text"
        defaultMessage="<p>By submitting this form, you agree to our <a1>terms of business</a1> and have read and acknowledged our <a2>privacy policy</a2>
                </p>"
        values={{
          p: (chunks) => <Text>{chunks}</Text>,
          a1: (chunks) => (
            <Link url="https://www.wayflyer.com/terms">{chunks}</Link>
          ),
          a2: (chunks) => (
            <Link url="https://www.wayflyer.com/privacy-notice">{chunks}</Link>
          ),
        }}
      />
    </TAndCBox>
  );
};

export const SignInCTA = ({ linkClickHandler }: SignInCTAProps) => {
  const intl = useIntl();
  return (
    <Text element="span" color="default">
      {intl.formatMessage({
        id: "auth: auth signup already have an account",
        defaultMessage: "Already have an account?",
      })}{" "}
      <Link testId="login-link" onClick={linkClickHandler}>
        {intl.formatMessage({
          id: "auth: auth login",
          defaultMessage: "Sign in",
        })}
      </Link>
    </Text>
  );
};

export const GoogleSignUpForm = ({
  oauthError,
  googleButtonClickHandler,
  loginAttrs,
}: GoogleSignupProps) => {
  const intl = useIntl();
  return (
    <FormLayout>
      <GoogleButton
        primary
        fullWidth
        onClick={googleButtonClickHandler}
        label={intl.formatMessage({
          id: "auth: auth sign up with Google",
          defaultMessage: "Sign up with Google",
        })}
        analyticsAttrs={loginAttrs}
      />
      {oauthError && <NotificationBanner notification={oauthError} translate />}
    </FormLayout>
  );
};

export const GoogleButtonDivider = styled(Divider)`
  transform: translateY(-${({ theme }) => theme.spacing("2")});
`;
