import { logger } from "@wayflyer/blackbox-fe";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export interface FallbackRedirectProps {
  to: string;
}

export const FallbackRedirect = ({
  to,
}: FallbackRedirectProps): JSX.Element => {
  const location = useLocation();
  logger.warn(`fallback redirect: ${location.pathname} -> ${to}`, {
    from: location.pathname,
    to,
  });

  const navigate = useNavigate();
  useEffect(() => {
    navigate(to, { replace: true });
  }, [navigate, to]);

  return <></>;
};
