import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { Public } from "../wrappers/public";
import { Protected } from "../wrappers/protected";
import { ROUTES, routeWrapper, sharedRoutes } from "../sharedRoutes";

const SignUp = lazy(() => import("../../../screens/signup"));
const SignIn = lazy(() => import("../../../screens/signin"));
const PasswordReset = lazy(() => import("../../../screens/passwordReset"));
const PasswordSubmit = lazy(() => import("../../../screens/passwordSubmit"));
const Authenticated = lazy(() => import("../../../screens/authenticated"));
const Redirect = lazy(() => import("../../../screens/redirect"));

export const customerRoutes: RouteObject[] = [
  {
    path: ROUTES.LOGIN,
    element: routeWrapper({
      component: SignIn,
      wrapper: Public,
    }),
  },
  {
    path: ROUTES.SIGNUP,
    element: routeWrapper({
      component: SignUp,
      wrapper: Public,
    }),
  },
  {
    path: ROUTES.PASSWORD_RESET,
    element: routeWrapper({
      component: PasswordReset,
      wrapper: Public,
    }),
  },
  {
    path: `${ROUTES.PASSWORD_SUBMIT}/:username/:code`,
    element: routeWrapper({
      component: PasswordSubmit,
      wrapper: Public,
    }),
  },
  {
    path: ROUTES.SUCCESS,
    element: routeWrapper({
      component: Authenticated,
      wrapper: Protected,
    }),
  },
  {
    path: ROUTES.REDIRECT,
    element: routeWrapper({
      component: Redirect,
      wrapper: Protected,
    }),
  },
  ...sharedRoutes,
];
