import { BaseConfig, Scripts } from "./Scripts";

interface CrowdinConfig extends BaseConfig {
  domain: string;
}

export const Crowdin = (config: CrowdinConfig) => {
  return (
    <Scripts<CrowdinConfig>
      config={config}
      scripts={[
        {
          type: "script",
          content: ({ id, domain = "wayflyer" }) => `var _jipt = [];
          _jipt.push(['project', '${id}']);
          _jipt.push(['domain', '${domain}'])`,
        },
        {
          type: "script",
          attributes: {
            src: "//cdn.crowdin.com/jipt/jipt.js",
            type: "text/javascript",
          },
        },
      ]}
    />
  );
};
