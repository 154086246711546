import { useAuth } from "@wayflyer/auth-sdk";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setUser, setUserProperty } from "@wayflyer/blackbox-fe";

import { ROUTES } from "../../sharedRoutes";

/**
 * Public component
 */
export const Public: FC = ({ children }): JSX.Element => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth && !auth.isLoading && auth.session?.isValid()) {
      const userId = localStorage.getItem("userId");
      userId && setUser({ id: localStorage.getItem("userId") || undefined });
      if (auth?.isSuccessfulResume) {
        setUserProperty("validSessionRedirect", true);
        sessionStorage.setItem("validSessionRedirect", "true"); // to be picked up on the customer app side
      }
      navigate(`../${ROUTES.SUCCESS}`, { replace: true });
    }
  }, [auth, auth?.session, navigate]);

  return <>{children}</>;
};
