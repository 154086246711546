import styled from "styled-components";
import { Text, Flex, Link } from "@wayflyer/flyui";
import { useIntl } from "react-intl";

const StyledLoginFormLinks = styled.div`
  margin-top: ${({ theme }) => theme.spacing("4")};
`;

export interface LoginFormLinkProps {
  forgotPasswordClicked?: () => void;
  hideForgotPasswordLink?: boolean;
  linkClickHandler: () => void;
}

export const LoginFormLinks = ({
  linkClickHandler,
  hideForgotPasswordLink,
  forgotPasswordClicked,
}: LoginFormLinkProps) => {
  const intl = useIntl();
  return (
    <Flex direction="column" justify="space-between">
      {!hideForgotPasswordLink && (
        <Link testId="password-reset" onClick={forgotPasswordClicked}>
          {intl.formatMessage({
            id: "auth: forgot password button",
            defaultMessage: "Forgotten your password?",
          })}
        </Link>
      )}
      <StyledLoginFormLinks>
        <Text element="span" color="default">
          {intl.formatMessage({
            id: "auth: login do not have an account",
            defaultMessage: "Don't have an account?",
          })}{" "}
          <Link testId="signup-link" onClick={linkClickHandler}>
            {intl.formatMessage({
              id: "auth: login signup",
              defaultMessage: "Sign up",
            })}
          </Link>
        </Text>
      </StyledLoginFormLinks>
    </Flex>
  );
};
