import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import { Loading } from "@wayflyer/auth-ui";

import { Protected } from "./wrappers/protected";
import { Public } from "./wrappers/public";
import { FallbackRedirect } from "./FallbackRedirect";

const Oauth = lazy(() => import("../../screens/oauth"));
const Signout = lazy(() => import("../../screens/signout"));

export enum ROUTES {
  // Public
  LOGIN = "login",
  OAUTH = "a/oauth",

  // Public (Customers)
  PASSWORD_RESET = "a/password-reset",
  PASSWORD_SUBMIT = "a/password-submit",
  SIGNUP = "signup",

  // Protected
  REDIRECT = "a/redirect",
  SUCCESS = "a/success",
  LOGOUT = "logout",
}

interface RouteWrapperProps {
  wrapper: React.FunctionComponent<any>;
  component: React.FunctionComponent<any>;
}

export const routeWrapper = ({
  wrapper: Wrapper,
  component: Component,
}: RouteWrapperProps) => {
  return (
    <Wrapper>
      <Suspense fallback={<Loading />}>
        <Component />
      </Suspense>
    </Wrapper>
  );
};

export const sharedRoutes: RouteObject[] = [
  {
    path: ROUTES.OAUTH,
    element: routeWrapper({
      component: Oauth,
      wrapper: Public,
    }),
  },
  {
    path: ROUTES.LOGOUT,
    element: routeWrapper({
      component: Signout,
      wrapper: Protected,
    }),
  },
  {
    path: "*",
    element: <FallbackRedirect to={ROUTES.LOGIN} />,
  },
];
