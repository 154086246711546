import { BrowserRouter, useRoutes } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { FlyUIProvider } from "@wayflyer/flyui";
import React from "react";

import English from "../compiled-lang/en.json";
import German from "../compiled-lang/de.json";
import Spanish from "../compiled-lang/es.json";
import thirdPartyConfig from "../configs/config.third-party.json";

import { Plugins } from "./plugins/Plugins";
import { USE_MOCKS } from "./utils/env";
import App from "./App";
import Auth0App from "./Auth0App";

const getLocale = () => navigator.language;

const loadMessages = () => {
  const locale = getLocale();
  if (locale.startsWith("es")) {
    return Spanish;
  } else if (locale.startsWith("de")) {
    return German;
  }
  return English;
};

const intl = createIntl(
  {
    messages: loadMessages(),
    locale: getLocale(),
    defaultLocale: "en",
  },
  createIntlCache(),
);

const initialiseMocks = async (): Promise<void> => {
  const { setupWorker } = await import("msw/browser");
  const { handlers } = await import("@wayflyer/auth-mocks");

  const worker = setupWorker(...(handlers as any));
  worker.start({ onUnhandledRequest: "warn" });
};

const config = (thirdPartyConfig as any)[location.hostname];

const Root = () => {
  const rendered = useRoutes([
    {
      path: "/*",
      element: <App />,
    },
    ...(__WF_AUTH_CONFIGS__.auth0.enabled
      ? [
          {
            path: "/auth/auth0/*",
            element: <Auth0App />,
          },
        ]
      : []),
  ]);

  return (
    <RawIntlProvider value={intl}>
      <Plugins {...config}>
        <FlyUIProvider theme="wayflyer" intl={intl}>
          {rendered}
        </FlyUIProvider>
      </Plugins>
    </RawIntlProvider>
  );
};

const renderApp = () => {
  const container = document.getElementById("root");
  if (!container) {
    throw new Error("Root element not found");
  }

  createRoot(container).render(
    <React.StrictMode>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </React.StrictMode>,
  );
};

USE_MOCKS ? initialiseMocks().then(renderApp) : renderApp();
