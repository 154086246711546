import { isLocalhost } from "./host";

export const WF_DEBUG_KEY = "wf-debug";
export const STAGING_BASE_URL = "https://app.auth-test.wayflyer.com/";

export const getBaseUrl = () => {
  return isLocalhost()
    ? (process.env.AUTH_BASE_URL ?? STAGING_BASE_URL)
    : window.location.origin;
};

export const WAYFLYER_DOT_COM = "https://www.wayflyer.com/";
