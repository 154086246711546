import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

const HISTORY_EXPIRY_SECS = 10;
const HISTORY_MAX_REDIRECT_BEFORE_ERROR = 3;

export const NEXT_QUERY_PARAM = "next";
export const NEXT_SESSION_STORAGE_KEY = "wayflyer-next";

interface GoNextHistoryEntry {
  count: number;
  expiresAt: number;
}

type GoNextHistory = Record<string, GoNextHistoryEntry>;

interface GoNextStorage {
  next: string | null;
  history: GoNextHistory;
}

class GoNextStorageManager {
  private store: GoNextStorage;

  constructor() {
    this.store = this.load();
  }

  get next(): string | null {
    return this.store.next;
  }

  set next(next: string | null) {
    this.store.next = next;
    this.save();
  }

  getHistory(path: string): GoNextHistoryEntry | undefined {
    const history = this.store.history?.[path];
    if (history?.expiresAt > Date.now()) {
      return history;
    }

    return undefined;
  }

  setHistory(path: string, count: number) {
    this.store.history[path] = {
      count,
      expiresAt: Date.now() + HISTORY_EXPIRY_SECS * 1000,
    };

    this.save();
  }

  save() {
    const store = this.age(this.store);
    window.sessionStorage.setItem(
      NEXT_SESSION_STORAGE_KEY,
      JSON.stringify(store),
    );
  }

  private load(): GoNextStorage {
    const storeJson = window.sessionStorage.getItem(NEXT_SESSION_STORAGE_KEY);
    if (storeJson) {
      const storage = JSON.parse(storeJson) as GoNextStorage;
      if (storage) {
        return storage;
      }
    }

    return {
      next: null,
      history: {},
    };
  }

  private age(storage: GoNextStorage): GoNextStorage {
    const now = Date.now();

    const history = storage.history || {};
    const prunedHistory: GoNextHistory = {};
    for (const [key, entry] of Object.entries(history)) {
      if (entry.expiresAt > now) {
        prunedHistory[key] = entry;
      }
    }

    return {
      ...storage,
      history: prunedHistory,
    };
  }
}

export interface GoNextParams {
  /**
   * Default path to redirect to if the next page is not set.
   * @default "/"
   */
  defaultPath?: string;

  /**
   * Override the host to redirect to.
   *
   * @default current host
   */
  host?: string;

  /**
   * Do nothing if the next page is not set, rather than redirecting to the
   * root.
   */
  onlyIfSet?: boolean;
}

/**
 * Redirect to the next stored page, or root if not set.
 *
 * @param params see `GoNextParams`
 */
export type GoNext = (params?: GoNextParams) => void;

/**
 * Hook that returns a function to redirect to the next page.
 *
 * The next page is stored in session storage via the `next` query parameter
 * to persist through authentication flows, etc.
 */
export const useNext = (): GoNext => {
  const [searchParams] = useSearchParams();
  const nextParam = searchParams.get(NEXT_QUERY_PARAM);
  if (nextParam) {
    const store = new GoNextStorageManager();
    store.next = nextParam;
  }

  return useCallback<GoNext>((params?: GoNextParams) => {
    const store = new GoNextStorageManager();
    if (params?.onlyIfSet && !store?.next) {
      return;
    }

    const next = store?.next || params?.defaultPath || "/";
    const history = store.getHistory(next);

    if (history && history.count >= HISTORY_MAX_REDIRECT_BEFORE_ERROR) {
      store.next = null;
      throw new Error(`Infinite redirect loop detected for ${next}`);
    }

    store.setHistory(next, (history?.count || 0) + 1);

    // Strip trailing slashes from host and add a leading slash to the path.
    const host = (params?.host || window.location.origin).replace(/\/+$/, "");
    const path = "/" + next.replace(/^\/+/, "");

    store.next = null;
    window.open(`${host}${path}`, "_self");
  }, []);
};
