import { PropsWithChildren, useEffect } from "react";
import { usePostHog } from "posthog-js/react";

import { AnalyticsContext } from "./AnalyticsContext";
import { FlyuiAnalytics } from "./FlyuiAnalytics";
import { RouterAnalytics } from "./RouterAnalytics";

type AnalyticsNestProps = PropsWithChildren<Record<string, unknown>>;

export const AnalyticsNest = ({ children }: AnalyticsNestProps) => {
  const posthog = usePostHog();
  useEffect(() => {
    if (posthog) posthog.register_once({ event_source: "app" });
  }, [posthog]);
  return (
    <AnalyticsContext service="auth">
      <RouterAnalytics>
        <FlyuiAnalytics>{children}</FlyuiAnalytics>
      </RouterAnalytics>
    </AnalyticsContext>
  );
};
