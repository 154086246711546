import { pushEventOrTimeout } from "./common";

import { WfChannel, WfEvent } from ".";

declare global {
  interface Window {
    dataLayer: Array<any>;
    google_tag_manager: any;
  }
}

export const gaChannel: WfChannel = async (event: WfEvent) => {
  const eventPromise = new Promise((resolve) => {
    window.dataLayer?.push({ ...event, eventCallback: resolve });
  });
  return pushEventOrTimeout(eventPromise);
};
