const defaultEventTimeout = 1500;

export function pushEventOrTimeout(
  eventPromise: Promise<unknown>,
): Promise<unknown> {
  return Promise.race([
    new Promise((_, reject) =>
      setTimeout(
        () => reject("timed out when attempting to track analytics event"),
        defaultEventTimeout,
      ),
    ),
    eventPromise,
    // Removed addError being passed exception thrown from rejection as it's causing a lot of noise in DD for timeouts.
  ]).catch((e) => e);
}
