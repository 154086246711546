import { Banner } from "@wayflyer/flyui";
import { useIntl } from "react-intl";

import { useMessages } from "../../hooks/useMessages";
import type { MessageObject } from "../../screens/interface";

export const NotificationBanner = (props: {
  notification: MessageObject | string;
  translate?: boolean;
}) => {
  const intl = useIntl();
  const { notification, translate } = props;
  const errorLookup = useMessages(intl);

  if (typeof notification === "string") {
    return (
      <Banner variant="critical" data-testid="server-error">
        {notification}
      </Banner>
    );
  }

  const { message, heading, type } = notification;
  return (
    <Banner
      variant={type}
      title={heading}
      data-testid={type === "critical" ? "server-error" : "banner-notification"}
    >
      {translate ? errorLookup(message) : message}
    </Banner>
  );
};
