import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate, useRoutes, useSearchParams } from "react-router-dom";
import type {
  Auth0ProviderOptions,
  AuthorizationParams,
  IdToken,
} from "@auth0/auth0-react";
import { clearIdTokenCookie, setIdTokenCookie } from "@wayflyer/auth-sdk";

import { FallbackRedirect } from "./components/routes/FallbackRedirect";
import { FullScreenLoader } from "./components/FullscreenLoader";
import { useNext } from "./hooks/useNext";

const STAFF_CONNECTION = "okta-staff";

interface LoginProps {
  logoutTo: string;
  staff?: boolean;
}

interface WayflyerIdToken extends IdToken {
  connection: string;
}

function Login(props: LoginProps) {
  const {
    error,
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();
  const goNext = useNext();
  const navigate = useNavigate();

  if (error) {
    // Let Blackbox error boundary handle it. This would be something
    // catastrophic and unhandable.
    throw error;
  }

  useEffect(() => {
    const authorizationParams: AuthorizationParams = {
      connection: props.staff ? STAFF_CONNECTION : undefined,
      redirect_uri: window.location.origin + window.location.pathname,
    };

    if (isLoading || error) {
      return;
    } else if (!isAuthenticated) {
      loginWithRedirect({ authorizationParams });
    } else {
      (async () => {
        const claims = (await getIdTokenClaims()) as
          | WayflyerIdToken
          | undefined;
        if (!claims) {
          throw new Error(
            "Auth0 said we were authenticated, but didn't give us a token.",
          );
        } else {
          if (!claims.connection) {
            throw new Error("Expected connection claim.");
          }
        }

        // Force a logout if the user hasn't come from the right connection,
        // i.e., Okta vs. user/pass since Auth0 won't do it for us, at least.
        //
        // Specifcally:
        //   `connection: okta-staff` will always force an Okta login but
        //   `connection: undefined` will pass any allowed connection.
        //
        // If a staff user is logged in and wants to switch to a customer
        // (e.g., for testing), Auth0 would not force a logout and ask them
        // to log in fresh with user/pass. So, we do it ourselves.
        if (
          (props.staff && claims.connection !== STAFF_CONNECTION) ||
          (!props.staff && claims.connection === STAFF_CONNECTION)
        ) {
          navigate({
            pathname: props.logoutTo,
            search: props.staff ? "?to=staff" : undefined,
          });
          return;
        }

        const token = await getAccessTokenSilently({
          authorizationParams,
          cacheMode: "off",
          detailedResponse: true,
        });
        setIdTokenCookie(token.id_token);

        goNext({ defaultPath: props.staff ? "/staff/" : undefined });
      })();
    }
  }, [
    error,
    getAccessTokenSilently,
    getIdTokenClaims,
    goNext,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    navigate,
    props.logoutTo,
    props.staff,
  ]);

  return null;
}

interface LogoutProps {
  defaultReturnTo: string;
  staffReturnTo: string;
}

function Logout(props: LogoutProps) {
  const { logout } = useAuth0();
  const [searchParams] = useSearchParams();

  const returnToMap = {
    staff: props.staffReturnTo,
    cognito: "/login",
  };

  const toParam = searchParams.get("to");
  if (toParam && !(toParam in returnToMap)) {
    throw new Error("Invalid 'to' parameter.");
  }
  const returnTo = toParam
    ? returnToMap[toParam as keyof typeof returnToMap]
    : props.defaultReturnTo;

  useEffect(() => {
    (async () => {
      await logout({
        logoutParams: {
          returnTo: window.location.origin + returnTo,
        },
      });
      clearIdTokenCookie();
    })();
  }, [logout, props.defaultReturnTo, props.staffReturnTo, returnTo, toParam]);

  return null;
}

export default function Auth0App() {
  const rendered = useRoutes([
    {
      path: "*",
      element: <FallbackRedirect to="/auth/auth0/login" />,
    },
    {
      path: "login",
      element: <Login logoutTo="/auth/auth0/logout" />,
    },
    {
      path: "login-staff",
      element: <Login logoutTo="/auth/auth0/logout" staff />,
    },
    {
      path: "logout",
      element: (
        <Logout
          defaultReturnTo="/auth/auth0/login"
          staffReturnTo="/auth/auth0/login-staff"
        />
      ),
    },
  ]);

  const auth0Props: Auth0ProviderOptions = {
    authorizationParams: {
      audience: "wayflyer",
    },
    cacheLocation: "localstorage",
    clientId: __WF_AUTH_CONFIGS__.auth0.clientId,
    domain: __WF_AUTH_CONFIGS__.auth0.domain,
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
  };

  return (
    <>
      <FullScreenLoader />
      <Auth0Provider {...auth0Props}>{rendered}</Auth0Provider>
    </>
  );
}
